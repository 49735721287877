input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* src/styles/animations.css */
@keyframes bounce {
    0%, 20%, 0%, 20% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  .custom-bounce {
    animation: bounce 50s infinite;
  }

